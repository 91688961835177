<template>
    <fragment>
        <div v-if="lock && loaded" class="preloader">
            <div class="trade_preload">
                Торговля заблокирована
                <br />
                Для связи с поддержкой обратитесь на электронную почту: support@coinblink.trade
            </div>
        </div>

        <div v-if="loading" class="preloader">
            <div class="trade_preload">
                <img src="../assets/img/loading_.svg" class="preload_img">
            </div>
        </div>
        <Header v-if="!lock && loaded" />

        <router-view v-if="!lock && loaded" />

        <Footer v-if="!lock && loaded" />
    </fragment>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import ENUM from "../config/enums"

export default {
    mounted() {
        document.body.id = 'dark'
        this.$socket.emit('getAssets')
        this.$socket.emit('getBalance', JSON.parse(localStorage.getItem('token')))
        this.$socket.emit('getOrders', JSON.parse(localStorage.getItem('token')))
        setInterval(() => {
            this.$socket.emit('getBalance', JSON.parse(localStorage.getItem('token')))
            this.$socket.emit('getOrders', JSON.parse(localStorage.getItem('token')))
        }, 2800)
        setInterval(() => {
            this.$socket.emit('getAssets')
        }, 60000)
    },
    sockets: {
        balance(data) {
            this.$store.dispatch('balance/balance', data)
        },
        orders(data) {
            this.$store.dispatch('balance/orders', data)
        },
        assets(data) {
            this.$store.dispatch('data/assets', data)
        }
    },
    computed: {
        lock() {
            if (!this.$store.state.auth.user) return false
            else return this.$store.state.auth.user.lock
        },
        load() {
            return this.$store.state.basic.loading
        },
        loaded() {
            return this.load === ENUM.LOADED
        },
        loading() {
            return this.load === ENUM.LOADING || this.load === ENUM.INIT
        }
    },
    components: {
        Header,
        Footer
    },
}
</script>

<style>
@import url('../assets/css/style.css');

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>